/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import SideNav from "./sideNav"

const Layout = ({ children, isHomePage }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [sideNavOpen, setSideNavOpen] = useState(false)

  return (
    <div className="m-auto min-h-screen h-full" id="home">
      <div className="bg-gray-100 dark:bg-gray-900 min-h-screen h-full">
        <Header
          siteTitle={data.site.siteMetadata?.title || `Title`}
          onSideNavOpen={() => {
            setSideNavOpen(!sideNavOpen)
          }}
          isHomePage={isHomePage}
        />
        <div className="overflow-hidden">
          <SideNav showing={sideNavOpen} isHomePage={isHomePage} />
        </div>
        <div
          style={{
            margin: `0 auto`,
          }}
          className="min-h-full"
        >
          <div
            id="backdrop"
            className={`${
              sideNavOpen ? "block" : "hidden"
            } w-screen h-screen bg-black/60 fixed top-0 left-0 z-30 transition-all md:hidden`}
          ></div>
          <main>{children}</main>
          <footer className="px-4 md:px-32 bg-purple-500 text-gray-800 dark:text-gray-300 dark:bg-gray-900 py-8 flex justify-between flex-col md:flex-row items-center md:items-start">
            <p>Made with ❤ in Munich, Germany</p>
            <div
              id="footer-additional-links"
              className="space-x-2 mt-4 md:mt-0"
            >
              <Link to="/legal-notice">Legal notice</Link>
              <a
                href="https://www.iubenda.com/privacy-policy/83568777"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              <a
                href="https://www.iubenda.com/privacy-policy/83568777/cookie-policy"
                target="_blank"
                rel="noreferrer"
              >
                Cookie Policy
              </a>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHomePage: PropTypes.bool,
}

export default Layout
