/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({
  description,
  lang,
  meta,
  title,
  displayImage,
  keywords,
  scripts,
  links,
}) {
  let allMeta = [...meta]
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  if (displayImage) {
    allMeta.push({
      name: "og:image",
      content: displayImage,
    })
  }
  if (keywords) {
    keywords.forEach(keyword => {
      allMeta.push({
        property: "article.tag",
        content: keyword,
      })
    })
  }
  allMeta = [
    {
      name: "icon",
      href: "../images/favicon.png",
    },
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata?.author || ``,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ].concat(allMeta)

  const pScript = `
  if(typeof window !== "undefined")
  {
    window.plausible =
      window.plausible ||
      function () {
        (window.plausible.q = window.plausible.q || []).push(arguments)
      }
  }
  `

  const mcScript = `
  !function(c,h,i,m,p){
    m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)
  }
  (document,"script","https://chimpstatic.com/mcjs-connected/js/users/703d9160efc2d599e965df310/6d22974859c50666179896d5d.js");
  `
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={allMeta}
    >
      <script
        defer
        data-domain="dennissimon.dev"
        data-api="/ana/api/event"
        src="/ana/js/script.js"
      />
      <script dangerouslySetInnerHTML={pScript} />
      <script id="mcjs" dangerouslySetInnerHTML={mcScript} />
      {scripts?.map(({ html, ...rest }) => (
        <script
          {...(html ? { ...rest, dangerouslySetInnerHTML: html } : rest)}
        />
      )) ?? null}
      {links?.map(link => <link {...link} />) ?? null}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  displayImage: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
}

export default Seo
