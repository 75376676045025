import React from "react"
import { bool } from "prop-types"
import NavComponent from "./navComponent"

const SideNav = ({ showing, isHomePage }) => {
  const prefix = isHomePage ? "" : "/"
  return (
    <div
      className={`${
        showing ? "-right-0" : "-right-full"
      } fixed top-0 z-40 transition-all`}
    >
      <NavComponent prefix={prefix} />
    </div>
  )
}

SideNav.propTypes = {
  showing: bool,
  isHomePage: bool,
}

export default SideNav
