import { useEffect } from "react"
import useLocalStorage from "./useLocalStorage"

export default function useDarkMode() {
  const [mode, setMode] = useLocalStorage(
    "darkMode",
    typeof window === `undefined` ||
      window.matchMedia("(prefers-color-scheme: light)").matches
      ? false
      : true
  )

  useEffect(() => {
    const className = "dark"
    const element = window.document.body

    if (mode) {
      element.classList.add(className)
    } else {
      element.classList.remove(className)
    }
  }, [mode])

  return [mode, setMode]
}
