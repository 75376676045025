import React from "react"
import { string } from "prop-types"
import { Link } from "gatsby"

const NavComponent = ({ prefix }) => {
  const makeLink = post => `${prefix}${post}`
  return (
    <nav
      className={`font-sans justify-self-end grow bg-gray-100 pt-40 md:pt-0 -z-10 md:z-auto
    md:bg-transparent flex h-screen md:h-auto flex-col md:flex-row justify-start
    transition-all dark:bg-darkBg md:dark:bg-transparent
    md:justify-end items-center text-2xl pr-4 md:pr-0 text-gray-800 dark:text-gray-300`}
    >
      <Link
        className="ml-4 md:ml-4 lg:ml-10 mt-4 md:mt-0"
        to={makeLink("#home")}
      >
        Home
      </Link>
      <Link
        className="ml-4 md:ml-4 lg:ml-10 mt-4 md:mt-0"
        to={makeLink("#about")}
      >
        About
      </Link>
      <Link
        className="ml-4 md:ml-4 lg:ml-10 mt-4 md:mt-0"
        to={makeLink("#skills")}
      >
        Skills
      </Link>
      <Link
        className="ml-4 md:ml-4 lg:ml-10 mt-4 md:mt-0"
        to={makeLink("#contact")}
      >
        Contact
      </Link>
      <Link
        className="ml-4 md:ml-4 lg:ml-10 mt-4 md:mt-0"
        to={makeLink("#blog")}
      >
        Blog
      </Link>
    </nav>
  )
}

NavComponent.propTypes = {
  prefix: string,
}

export default NavComponent
