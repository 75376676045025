import React from "react"
import PropTypes from "prop-types"
import Logo from "../svg/ds.svg"
import NavComponent from "./navComponent"
import DarkModeToggle from "./darkModeToggle"
import { Link } from "gatsby"

const breakPoint = 250

const collapseDelta = 160 - 80

const Header = ({ onSideNavOpen, isHomePage }) => {
  const [onScrollHandler, setOnScrollHandler] = React.useState(null)
  const [collapse, setCollapse] = React.useState(false)
  const prefix = isHomePage ? "" : "/"

  const handler = React.useCallback(() => {
    const scrollProgress =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0

    if (scrollProgress < breakPoint - collapseDelta && collapse) {
      setCollapse(false)
    } else if (scrollProgress >= breakPoint && !collapse) {
      setCollapse(true)
    }
  }, [collapse])

  React.useEffect(() => {
    if (onScrollHandler === handler) return
    if (onScrollHandler !== null) {
      window.removeEventListener("scroll", onScrollHandler)
    }
    window.addEventListener("scroll", handler)
    setOnScrollHandler(handler)
  }, [onScrollHandler, handler])

  return (
    <header
      className={`${
        !collapse && "md:h-40 "
      } h-20 mb-6 px-12 md:px-12 lg:px-32 sticky top-0 bg-gray-100 dark:bg-gray-900 transition-all z-50 shadow-md md:shadow-none w-full`}
    >
      <div className="flex justify-between items-center h-full ">
        <Link to="/#home" className="flex items-center">
          <Logo className="md:h-16 h-8 inline-block text-purple-500 mr-6" />
          <span className="m-0 p-0 font-sans font-bold text-2xl inline-block text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-black dark:to-white">
            Dennis Simon
          </span>
        </Link>
        <div className="flex">
          <div className="hidden md:block">
            <NavComponent prefix={prefix} />
          </div>
          <div className="flex ml-8">
            {typeof window !== "undefined" && <DarkModeToggle />}

            <div className="md:hidden flex items-center">
              <button
                className="outline-none mobile-menu-button z-50 ml-4"
                onClick={onSideNavOpen}
              >
                <svg
                  className="w-6 h-6 text-gray-500 dark:text-white"
                  x-show="!showMenu"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  onSideNavOpen: PropTypes.func,
  isHomePage: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
