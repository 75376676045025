import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons"
import useDarkMode from "../hooks/useDarkMode"

export default function DarkModeToggle() {
  const [darkMode, setDarkMode] = useDarkMode()
  return (
    <label
      htmlFor="darkModeToggle"
      className="flex items-center cursor-pointer"
    >
      <div className="relative">
        <input
          type="checkbox"
          id="darkModeToggle"
          className="sr-only"
          checked={darkMode}
          onChange={() => {
            setDarkMode(!darkMode)
          }}
        />

        <div className="block bg-purple-900 dark:bg-gray-200 w-14 h-8 rounded-full" />

        <div className="dot absolute left-1 top-1 dark:bg-purple-900 bg-white w-6 h-6 rounded-full transition flex justify-center items-center">
          {darkMode ? (
            <FontAwesomeIcon
              icon={faMoon}
              className="p-0 m-0 h-4 w-4 text-yellow-500"
            />
          ) : (
            <FontAwesomeIcon
              icon={faSun}
              className="p-0 m-0 h-4 w-4 text-yellow-500"
            />
          )}
        </div>
      </div>
    </label>
  )
}
